import { getStoredLanguage } from "src/components/language-selector/utils";
import { AUTH_LOCAL_STORAGE_KEYS } from "src/modules/auth";

class Services {
  getUserLocalStorageToken() {
    const token = localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.AUTH_TOKEN);
    return token;
  }

  getSharedLocalStorageToken() {
    const token = localStorage.getItem("@shared-token");
    return token;
  }

  private buildAuthorizationHeader(token: string | null) {
    return token ? { Authorization: "Bearer ".concat(token) } : null;
  }

  getTokenAuthHeader() {
    const token = this.getUserLocalStorageToken();
    return this.buildAuthorizationHeader(token);
  }

  getSharedTokenAuthHeader() {
    const token = this.getSharedLocalStorageToken();
    return this.buildAuthorizationHeader(token);
  }

  getLanguageHeader() {
    const language = getStoredLanguage();
    return language ? { "Accept-Language": language } : null;
  }
}

export const AuthServices = new Services();
