import { useMutation } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";

export interface Response {
  data: {
    authId: string;
    token: string;
    companyId: number;
  };
}

interface IProps {
  onSuccess: (data: Response) => void;
  onError: (error: any) => void;
}

export function useMfaAuth({ onSuccess, onError }: IProps) {
  const mfaAuthMutation = useMutation(
    (data: { code: string; authId: string }) => {
      return API_AUTH_V2.post<Response>("/auth/mfa", data);
    },
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: (error: any) => {
        onError(error.response.data);
      },
    },
  );

  return { mfaAuthMutation };
}
