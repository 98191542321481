import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { SOCIAL_PROVIDERS_REDIRECT } from "../../constants";
import { FormPrimaryTitle } from "../form-fields/headings";
import {
  AuthFormBaseHeader,
  AuthFormBaseWrapper,
  AuthFormBaseMainProvider,
  AuthFormBaseSocialProviders,
  AuthFormBaseChildren,
  AuthFormBaseOthersProviders,
} from "./styles";
export * from "./styles";

type Props = {
  children: ReactNode;
  title: string;
};

export function AuthFormBase({ children, title }: Props) {
  const { t } = useTranslation();

  const googleRedirect = () => {
    window.open(SOCIAL_PROVIDERS_REDIRECT.GOOGLE, "_self");
  };

  const microsoftRedirect = () => {
    window.open(SOCIAL_PROVIDERS_REDIRECT.MICROSOFT, "_self");
  };

  const linkedinRedirect = () => {
    window.open(SOCIAL_PROVIDERS_REDIRECT.LINKEDIN, "_self");
  };

  return (
    <AuthFormBaseWrapper>
      <AuthFormBaseHeader>
        <FormPrimaryTitle>{title}</FormPrimaryTitle>

        <AuthFormBaseSocialProviders>
          <AuthFormBaseMainProvider type='button' onClick={microsoftRedirect}>
            <img
              src='https://storage.googleapis.com/inn-generic-assets/images/microsoft-logo.svg'
              alt={t("continue_with_microsoft")}
            />

            {t("continue_with_microsoft")}
          </AuthFormBaseMainProvider>

          <AuthFormBaseOthersProviders>
            <button type='button' onClick={googleRedirect}>
              <img
                src='https://storage.googleapis.com/inn-generic-assets/images/google-logo.svg'
                alt={t("continue_with_google")}
              />
            </button>
            <button type='button' onClick={linkedinRedirect}>
              <img
                src='https://storage.googleapis.com/inn-generic-assets/images/linkedin-logo.svg'
                alt={t("continue_with_microsoft")}
              />
            </button>
          </AuthFormBaseOthersProviders>
        </AuthFormBaseSocialProviders>
      </AuthFormBaseHeader>

      <AuthFormBaseChildren>{children}</AuthFormBaseChildren>
    </AuthFormBaseWrapper>
  );
}
