import { ReactNode } from "react";
import ReactHtmlParser from "react-html-parser";

import { FormPrimaryTitleStyled, FormSecondaryTitleStyled } from "./styles";

type FormPrimaryTitleProps = typeof FormPrimaryTitleStyled.defaultProps & {
  children: ReactNode;
};

export function FormPrimaryTitle({ children }: FormPrimaryTitleProps) {
  return (
    <FormPrimaryTitleStyled>{ReactHtmlParser(children as string)}</FormPrimaryTitleStyled>
  );
}

type FormSecondaryTitleProps = typeof FormSecondaryTitleStyled.defaultProps & {
  children: ReactNode;
};

export function FormSecondaryTitle({ children }: FormSecondaryTitleProps) {
  return (
    <FormSecondaryTitleStyled>{ReactHtmlParser(children as string)}</FormSecondaryTitleStyled>
  );
}
