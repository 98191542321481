import React, { ReactNode } from "react";
import { FiAlertCircle } from "react-icons/fi";

import { ModalDefaultTitleWrapper } from "./styles";

type Props = {
  children: ReactNode;
  Icon?: ReactNode;
};

export function ModalDefaultTitle({ children, Icon }: Props) {
  return (
    <ModalDefaultTitleWrapper>
      {Icon && Icon}
      {!Icon && <FiAlertCircle size='100%' />}
      <strong>{children}</strong>
    </ModalDefaultTitleWrapper>
  );
}
