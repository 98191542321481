import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ExtendedButton } from "src/components/v2/buttons";
import { AuthFormBaseFormGroup, AuthFormBaseWrapper } from "../auth-form-base";
import { FormSecondaryTitle } from "../form-fields/headings";
import { InputField } from "../form-fields/input";

interface IProps {
  handleSubmitForm: (code: string) => void;
  isLoading?: boolean;
}

export function VerifyMfaAuthForm({ handleSubmitForm, isLoading }: IProps) {
  const { t } = useTranslation();
  const [code, setCode] = useState("");

  return (
    <AuthFormBaseWrapper>
      <FormSecondaryTitle>{t("multi_factor_authentication_message")}</FormSecondaryTitle>

      <AuthFormBaseFormGroup css={{ gap: "$2" }}>
        <InputField
          label={t("code_mfa")}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />

        <ExtendedButton
          text={t("confirm")}
          variant='contained'
          color='primary'
          size='large'
          type='submit'
          isLoading={isLoading}
          onClick={() => handleSubmitForm(code)}
          style={{
            width: "100%",
          }}
          disabled={!code || isLoading || code.length < 6}
        />
      </AuthFormBaseFormGroup>
    </AuthFormBaseWrapper>
  );
}
