import { useMutation } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";

export interface IConfirmMfaSetupResponse {
  data: {
    authId: string;
    confirmed: boolean;
    token: string | null;
  };
}

interface Props {
  onSuccess: (data: IConfirmMfaSetupResponse) => void;
  onError: (error: any) => void;
}

export function useConfirmMfaSetup({ onSuccess, onError }: Props) {
  const confirmMfaSetupMutation = useMutation(
    (data: { code: string; authId: string; returnJwt?: boolean }) => {
      return API_AUTH_V2.post<IConfirmMfaSetupResponse>("/auth/mfa/setup/confirm", data);
    },
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: (error: any) => {
        onError(error.response.data);
      },
    },
  );

  return { confirmMfaSetupMutation };
}
