import esLogo from "../../assets/spain-flag-medium.png";
import enLogo from "../../assets/united-states-of-america-flag-small.png";
import ptLogo from "../../assets/brazil-flag-medium.png";
import { GLOBAL_CONSTANTS } from "src/shared/constants";

export const languages = [
  {
    name: "English",
    code: "en",
  },
  {
    name: "Português (Brasil)",
    code: "pt",
  },
  {
    name: "Español",
    code: "es",
  },
];

export const languagesLogos = {
  EN: enLogo,
  PT: ptLogo,
  ES: esLogo,
};

export function storeLanguage(language) {
  localStorage.setItem(GLOBAL_CONSTANTS.LANGUAGE_STORAGE_KEY, language);
}

export function getStoredLanguage() {
  return localStorage.getItem(GLOBAL_CONSTANTS.LANGUAGE_STORAGE_KEY) || "en";
}
