import axios from "axios";
import { ENVS } from "src/shared/constants";
import { AuthServices } from "../auth";

const baseUrl = ENVS.AUTH_API_URL;

export const API_AUTH_V2 = axios.create({
  baseURL: baseUrl,
});

API_AUTH_V2.interceptors.request.use(
  (config) => {
    const header = AuthServices.getTokenAuthHeader();
    const languageHeader = AuthServices.getLanguageHeader();

    if (header) {
      config.headers.Authorization = header.Authorization;
    }

    if (languageHeader) {
      config.headers["Accept-Language"] = languageHeader["Accept-Language"];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
