import React, { useEffect } from "react";
import { QueryClientProvider } from "react-query";
import { Toaster } from "react-hot-toast";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { LoadingSpinner } from "./components/loadings-spinners";
import AuthProvider from "./contexts/auth";
import NoticationProvider from "./contexts/notification";
import { GoalsAndProjectsProvider } from "./contexts/goals-and-projects-context/index.jsx";
import CustomThemeProvider from "./styles/theme.jsx";
import { socket } from "./services/socket";
import { useLogoutUser } from "./modules/auth/hooks/useLogoutUser";
import { CookiesProvider } from "react-cookie";

import "./services/i18n";
import "swiper/css/bundle";
import "./App.css";
import { queryClient } from "./services/query-client";

const Routes = React.lazy(() => import("./routes/index"));

function App() {
  const { logoutApplication } = useLogoutUser();

  useEffect(() => {
    const userId = Number(localStorage.getItem("id"));

    socket.emit("join_room", userId);

    socket.on("LOGOUT_USER_EVENT", () => {
      logoutApplication();
      socket.emit("leave_room", userId);
    });

    return () => {
      socket.off("LOGOUT_USER_EVENT");
      socket.off("connect");
      socket.off("join_room");
      socket.off("leave_room");
    };
  }, [logoutApplication]);

  return (
    <CustomThemeProvider>
      <CookiesProvider>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <React.Suspense fallback={<LoadingSpinner />}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <NoticationProvider>
                  <GoalsAndProjectsProvider>
                    <Routes />

                    <Toaster
                      position='top-center'
                      reverseOrder={false}
                      containerStyle={{
                        zIndex: 999999999999,
                      }}
                    />
                  </GoalsAndProjectsProvider>
                </NoticationProvider>
              </LocalizationProvider>
            </React.Suspense>
          </QueryClientProvider>
        </AuthProvider>
      </CookiesProvider>
    </CustomThemeProvider>
  );
}

export default App;
