import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

import Loading from "src/components/loading";
import { ExtendedButton } from "src/components/v2/buttons";
import { useSetupMfa } from "../../hooks/useSetupMfa";
import { AuthFormBaseFormGroup, AuthFormBaseWrapper } from "../auth-form-base";
import { InputField } from "../form-fields/input";
import { ModalDefaultTitle } from "src/components/modal-default-title";
import { IConfirmMfaSetupResponse, useConfirmMfaSetup } from "../../hooks/useConfirmMfaSetup";
import {
  AUTH_LOCAL_STORAGE_KEYS,
  USER_AUTH_ERRORS_CODE_MAP_TRANSLATIONS,
} from "../../constants";
import { FormSecondaryTitle } from "../form-fields/headings";
import { customToast } from "src/components/v2/toast";

import { SetupMfaAuthBaseFormQrCode } from "./styles";

type Props = {
  onCloseMessageSuccess?: () => void;
  onFinish?: (data: IConfirmMfaSetupResponse) => void;
  returnJwt?: boolean;
};

export function SetupMfaAuthBaseForm({
  onCloseMessageSuccess = () => {},
  onFinish = () => {},
  returnJwt,
}: Props) {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [qrCode, setQrCode] = useState("");

  const { data: setupData, isFetching: isLoading } = useSetupMfa(
    localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.AUTH_ID) as string,
  );
  const { confirmMfaSetupMutation } = useConfirmMfaSetup({
    onSuccess: (data) => {
      customToast({
        message: t("setup_mfa_success"),
        type: "success",
        onClose: () => {
          onCloseMessageSuccess();
        },
      });

      onFinish(data);
    },
    onError: (data) => {
      customToast({
        message: t(USER_AUTH_ERRORS_CODE_MAP_TRANSLATIONS[data.error_code || ""]),
        type: "error",
      });
    },
  });

  useEffect(() => {
    if (setupData) {
      setQrCode(setupData.data.qrCode);
    }
  }, [setupData]);

  return (
    <>
      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <FormSecondaryTitle>{t("setup_mfa_message")}</FormSecondaryTitle>

          <SetupMfaAuthBaseFormQrCode>
            <img src={qrCode} alt='QR Code' />
          </SetupMfaAuthBaseFormQrCode>

          <AuthFormBaseFormGroup css={{ gap: "$2" }}>
            <InputField
              label={t("code_mfa")}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
            />

            <ExtendedButton
              text={t("confirm")}
              variant='contained'
              color='primary'
              size='large'
              type='submit'
              isLoading={confirmMfaSetupMutation.isLoading}
              onClick={() =>
                confirmMfaSetupMutation.mutate({
                  code,
                  authId: localStorage.getItem(AUTH_LOCAL_STORAGE_KEYS.AUTH_ID) as string,
                  returnJwt,
                })
              }
              style={{
                width: "100%",
              }}
              disabled={!code || confirmMfaSetupMutation.isLoading || code.length < 6}
            />
          </AuthFormBaseFormGroup>
        </>
      )}
    </>
  );
}

type SetupMfaAuthModalFormProps = {
  isOpenModal: boolean;
  closeModal: () => void;
};

export function SetupMfaAuthModalForm({
  isOpenModal,
  closeModal,
}: SetupMfaAuthModalFormProps) {
  const { t } = useTranslation();

  return (
    <Dialog open={isOpenModal} maxWidth='sm'>
      <DialogTitle>
        <ModalDefaultTitle>{t("enable_two_factor_authentication_title")}</ModalDefaultTitle>
      </DialogTitle>

      <DialogContent>
        <form
          style={{
            height: "100%",
            width: "100%",
            minWidth: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <SetupMfaAuthBaseForm onFinish={closeModal} />

          <DialogActions>
            <Button onClick={closeModal} color='primary'>
              {t("close")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

type SetupMfaAuthFormProps = {
  onFinish?: (data: IConfirmMfaSetupResponse) => void;
};

export function SetupMfaAuthForm({ onFinish = () => {} }: SetupMfaAuthFormProps) {
  return (
    <AuthFormBaseWrapper>
      <SetupMfaAuthBaseForm onFinish={onFinish} returnJwt />
    </AuthFormBaseWrapper>
  );
}
