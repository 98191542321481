import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, err: any) => {
        if (err.response?.status === 401) {
          return false; // do not retry, trigger error
        }

        // otherwise, restore default
        const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry;

        return Number.isSafeInteger(defaultRetry) ? failureCount < (defaultRetry ?? 0) : false;
      },
      onError: (err: any) => {
        if (err.response?.status === 401) {
          new QueryClient().clear();
          localStorage.clear();

          window.location.href = "/login";
        }
      },
    },
    mutations: {
      retry: (failureCount, err: any) => {
        if (err.response?.status === 401) {
          return false; // do not retry, trigger error
        }

        // otherwise, restore default
        const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry;

        return Number.isSafeInteger(defaultRetry) ? failureCount < (defaultRetry ?? 0) : false;
      },
      onError: (err: any) => {
        if (err.response?.status === 401) {
          new QueryClient().clear();
          localStorage.clear();
          window.location.href = "/login";
        }
      },
    },
  },
});
