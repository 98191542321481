import { Stitches } from "src/styles/stitches";

export const LanguageSelectorWrapper = Stitches.styled("div", {
  wiodth: "fit-content",
  display: "flex",
});

export const LanguageSelectorButton = Stitches.styled("button", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
  gap: "$1",
  background: "transparent",

  fontSize: "$2",
  fontWeight: "$bold",
  color: "$gray800",

  variants: {
    theme: {
      light: {
        color: "$whitePure",
      },
      gray: {
        color: "$gray800",
      },
    },
  },

  defaultVariants: {
    theme: "gray",
  },
});
