import { ENVS } from "src/shared/constants";

export const USER_AUTH_ERRORS_CODE_MAP_TRANSLATIONS = {
  COMPANY_IS_DISABLED: "company_is_disabled",
  INVALID_USER_EMAIL: "invalid_user_email",
  INVALID_USER_USERNAME: "invalid_user_username",
  USER_EMAIL_ALREADY_EXISTS: "user_email_already_exists",
  USER_NOT_FOUND: "user_not_found",
  INVALID_LOGIN_CREDENTIALS: "invalid_login_credentials",
  INVALID_PASSWORD_FORMAT: "invalid_password_format",
  INVALID_COMPANY_CODE: "invalid_company_code",
  PROVIDER_INCOMPATIBLE_WITH_PASSWORD_RECOVERY: "provider_incompatible_with_password_recovery",
  MAXIMUM_NUMBER_USERS_EXCEEDED: "maximum_number_users_exceeded",
  INVALID_RECOVERY_CODE: "invalid_recovery_code",
  RECOVERY_CODE_EXPIRED: "recovery_code_expired",
  INVALID_MFA_CODE: "invalid_mfa_code",
  INTERNAL_SERVER_ERROR: "internal_server_error",
  USER_CANCELLED_GOOGLE_AUTH: "user_cancelled_google_auth",
  USER_CANCELLED_MICROSOFT_AUTH: "user_cancelled_microsoft_auth",
  USER_CANCELLED_LINKEDIN_AUTH: "user_cancelled_linkedin_auth",
  AUTH_ID_NOT_FOUND: "auth_id_not_found",
} as { [key: string]: string };

export const SOCIAL_PROVIDERS_REDIRECT = {
  GOOGLE: `${ENVS.AUTH_API_URL}/auth/oauth2/google`,
  MICROSOFT: `${ENVS.AUTH_API_URL}/auth/oauth2/microsoft`,
  LINKEDIN: `${ENVS.AUTH_API_URL}/auth/oauth2/linkedin`,
};

export const AUTH_LOCAL_STORAGE_KEYS = {
  AUTH_ID: "@auth_id",
  AUTH_TOKEN: "@auth_token",
  COMPANY_ID: "cid",
  USER_EMAIL: "@user_email",
};
