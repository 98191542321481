import { useEffect, createContext } from "react";
import { useHistory } from "react-router-dom";
import create from "zustand";

import { reloadHubSpotChatbot } from "src/utils/hubspot";
import { useCallback } from "react";

export const MultiLanguageContext = createContext({});

export const useMultiLanguageStore = create((set) => ({
  language: localStorage.getItem("i18nextLng") || "en",
  setLanguage: (language) =>
    set((state) => ({
      ...state,
      language,
    })),
}));

export function MultiLanguageProvider({ children }) {
  const history = useHistory();
  const { language, setLanguage } = useMultiLanguageStore();

  const handlePushRoute = useCallback(
    (path, queryString) => {
      reloadHubSpotChatbot();
      history.replace(`${path}${queryString}`);
    },
    [history],
  );

  useEffect(() => {
    const storedlanguage = localStorage.getItem("i18nextLng");

    if (language !== storedlanguage) {
      setLanguage(storedlanguage);
    }
  }, [language, setLanguage]);

  useEffect(() => {
    const currentUrl = history.location.pathname;
    const queryString = history.location.search;
    const url = new URLSearchParams(queryString);

    if (currentUrl === "/") {
      handlePushRoute(`/${language}/search`, `?${url.toString()}`);
      return;
    }

    const languagePrefixRegex = new RegExp(/^\/(en|pt|es)\/?/);
    const languagePrefix = currentUrl.match(languagePrefixRegex);
    const languagePrefixMatch = languagePrefix && languagePrefix[1];

    if (languagePrefixMatch) {
      const newUrl = currentUrl.replace(languagePrefixMatch, `${language}`);
      const hasWithPrefix = newUrl.includes(`/${language}/`);

      if (hasWithPrefix) {
        handlePushRoute(newUrl, `?${url.toString()}`);
        return;
      }
    }

    handlePushRoute(`/${language}${currentUrl}`, `?${url.toString()}`);
  }, [handlePushRoute, history.location.pathname, history.location.search, language]);

  return <MultiLanguageContext.Provider value={{}}>{children}</MultiLanguageContext.Provider>;
}
