import IconButton from "@mui/material/IconButton";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { ControllerRenderProps } from "react-hook-form";
import TextField, { TextFieldProps } from "@mui/material/TextField";

type InputFieldProps = TextFieldProps & {
  hookFormProps?: ControllerRenderProps<any, any>;
  label: string;
  isError?: boolean;
};

type PasswordFieldProps = OutlinedInputProps & {
  hookFormProps?: ControllerRenderProps<any, any>;
  isError?: boolean;
  label: string;
};

export function InputField({
  hookFormProps,
  label,
  isError = false,
  ...props
}: InputFieldProps) {
  return (
    <FormControl variant='outlined'>
      <TextField
        variant='outlined'
        error={isError}
        label={label}
        {...hookFormProps}
        {...props}
      />
    </FormControl>
  );
}

export function PasswordInputField({
  hookFormProps,
  label,
  isError,
  ...props
}: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl variant='outlined'>
      <InputLabel error={isError} htmlFor={`auth-input-form-${label.replace(" ", "-")}`}>
        {label}
      </InputLabel>

      <OutlinedInput
        id={`auth-input-form-${label.replace(" ", "-")}`}
        type={showPassword ? "text" : "password"}
        label={label}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        error={isError}
        {...hookFormProps}
        {...props}
      />
    </FormControl>
  );
}
