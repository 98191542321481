import React, { useState, createContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/lab/Alert";

export const NotificationContext = createContext({});

const NotificationProvider = (props) => {
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);

  function Alert(props) {
    return <MuiAlert elevation={6} variant='filled' {...props} />;
  }

  function generalNotification(title) {
    setTitle(title);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <NotificationContext.Provider value={{ generalNotification }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          severity='success'
          action={
            <>
              <IconButton
                size='small'
                aria-label='close'
                color='inherit'
                onClick={handleClose}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </>
          }
        >
          {title}
        </Alert>
      </Snackbar>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
