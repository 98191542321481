import { getRem } from "./stitches";

export const THEME_TOKENS = {
  colors: {
    primary: "#4169E1",
    primaryUltraDark: "#121D3C",

    secondary: "#EB7F45",
    secondary80: "#FFE1D1",
    secondaryDark: "#D2691E",
    blue: "#177FFB",
    blue600: "#1679F0 ",
    blueSecondary: "#177FFB",
    blueSecondary80: "rgba(23, 127, 251, 0.4)",
    blueSecondary70: "rgba(23, 127, 251, 0.34)",
    blueSecondar25: "rgba(23, 127, 251, 0.25)",
    blue39: "rgba(23, 127, 251, 0.39)",
    blue20: "rgba(23, 127, 251, 0.2)",
    blueDark: "#195AA7 ",
    blue80: "#8CBCFF",
    white: "#FAFAFA",
    whitePure: "#FFFFFF",
    gray900: "#1C2A3A",
    gray800: "#37474F",
    gray700: "#455A64",
    gray600: "#6C7B82",
    gray500: "#7F8F96",
    gray400: "#A8B3B8",
    gray100: "#EBEBEB",
    gray200: "#D7E2E8",
    gray80: "#F0F0F0",
    gray300: "#efefef",
    gray450: "#B4B6BC",

    red: "#EA3B5B",
    redLight: "#FFC3CE",
    green: "#1D8F58",
    greenLight: "#C6F1CF",
    green80: "#B7EBD2",
    yellow: "#FFB400",
    yellowLight: "#FFE9A9",
    blueLight: "#BCDAFF",
    newGray400: "#F4F3F3",
    newGray500: "#524A4A",
    newGray450: "#CDCACA",
    neutral20: "#cdcdcd",

    pipelineBg: "#F5F5F5",
  },
  space: {
    1: getRem(8),
    2: getRem(16),
    3: getRem(24),
    4: getRem(32),
    5: getRem(40),
    6: getRem(48),
    7: getRem(48),
    8: getRem(56),
    9: getRem(64),
    10: getRem(72),
    11: getRem(80),
    section: getRem(88),
    headerHeight: getRem(70),
  },
  sizes: {
    1: getRem(30),
    2: getRem(42),
    3: getRem(48),
    4: getRem(56),
    5: getRem(64),
    iconSmall: getRem(20),
    iconMedium: getRem(22),
    iconLarge: getRem(24),
    headerHeight: getRem(70),
    pipelineWidth: getRem(290),
  },
  lineHeights: {
    1: getRem(21),
    2: getRem(24),
    3: getRem(28),
    4: getRem(32),
    5: getRem(36),
  },
  fontSizes: {
    1: getRem(13),
    2: getRem(14),
    3: getRem(16),
    4: getRem(18),
    5: getRem(20),
    6: getRem(24),
    7: getRem(28),
    8: getRem(32),
    9: getRem(36),
    10: getRem(40),
    11: getRem(44),
    12: getRem(48),
  },
  fontWeights: {
    medium: 500,
    semiBold: 600,
    regular: 400,
    bold: 700,
    light: 300,
  },
  radii: {
    default: getRem(4),
  },
  borderWidths: {
    default: getRem(1),
    2: getRem(2),
  },
  shadows: {
    default: " 0px 0px 15px 5px rgba(0, 0, 0, 0.05)",
    hover: "0px 0px 15px 5px rgba(0, 0, 0, 0.1)",
    newShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
  },
};
