import { Stitches } from "src/styles/stitches";

export const FormPrimaryTitleStyled = Stitches.styled("h3", {
  fontSize: "$8 !important",
  textAlign: "left",
  fontWeight: "$semiBold",
  color: "$gray800",
  margin: 0,
  padding: 0,
});

export const FormSecondaryTitleStyled = Stitches.styled("h3", {
  fontSize: "$5 !important",
  textAlign: "center",
  fontWeight: "$medium",
  color: "$gray800",
  margin: 0,
  padding: 0,
});
