import React from "react";

import "./styles.css";

export function LoadingSpinner() {
  return (
    <div className='loading-spinner'>
      <div className='loading-spinner__container'></div>
    </div>
  );
}
