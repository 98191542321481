import { useMutation } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";
import { IRegisterUserData } from "../dtos";

export interface IRegisterUserResponse {
  data: {
    authId: string;
  };
}

interface IUseRegisterUserProps {
  onSuccess: (data: IRegisterUserResponse) => void;
  onError: (error: any) => void;
}

export function useRegisterUser({ onSuccess, onError }: IUseRegisterUserProps) {
  const registerUserMutation = useMutation(
    (data: IRegisterUserData) => {
      return API_AUTH_V2.post<IRegisterUserResponse>("/auth/register/default", data);
    },
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: (error: any) => {
        onError(error.response.data);
      },
    },
  );

  return { registerUserMutation };
}
