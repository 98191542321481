import { useMutation } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";
import { ILoginUserData } from "../dtos";

export interface ILoginUserResponse {
  data: {
    authId: string;
    token: string | null;
    companyId: number | null;
    confirmed: boolean;
  };
}

interface IUseLoginUserProps {
  onSuccess: (data: ILoginUserResponse) => void;
  onError: (error: any) => void;
}

export function useLoginUser({ onSuccess, onError }: IUseLoginUserProps) {
  const loginUserMutation = useMutation(
    (data: ILoginUserData) => {
      return API_AUTH_V2.post<ILoginUserResponse>("/auth/login/default", data);
    },
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: (error: any) => {
        onError(error.response.data);
      },
    },
  );

  return { loginUserMutation };
}
