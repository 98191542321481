import { createStitches } from "@stitches/react";
import { THEME_TOKENS } from "./theme-tokens";

export function getRem(px: number) {
  return `${px / 16}rem`;
}

export const Stitches = createStitches({
  theme: THEME_TOKENS,
});
