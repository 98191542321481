import axios from "axios";
import { ENVS } from "src/shared/constants";
import { AuthServices } from "./auth";

const baseUrl = ENVS.BACK_END_API_URL;
const baseUrlFront = ENVS.APP_DOMAIN;

const API = axios.create({
  baseURL: baseUrl,
});

API.interceptors.response.use(
  (response) => {
    const newRes = { ...response };
    newRes.config.metadata.endTime = new Date();
    newRes.duration = newRes.config.metadata.endTime - newRes.config.metadata.startTime + "ms";
    return newRes;
  },
  (error) => {
    const newError = { ...error };
    newError.config.metadata.endTime = new Date();
    newError.duration = newError.config.metadata.endTime - newError.config.metadata.startTime;
    return Promise.reject(newError);
  },
);

API.interceptors.request.use(
  (config) => {
    const newConfig = { ...config };
    newConfig.metadata = { startTime: new Date() };

    const header = AuthServices.getTokenAuthHeader();
    const languageHeader = AuthServices.getLanguageHeader();

    if (header) {
      newConfig.headers.Authorization = header.Authorization;
    }

    if (languageHeader) {
      newConfig.headers["Accept-Language"] = languageHeader["Accept-Language"];
    }

    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  },
);

API.baseUrl = baseUrl;
API.baseUrlFront = baseUrlFront;

export default API;
