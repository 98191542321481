import { useQuery } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";

type Response = {
  data: {
    permitted: boolean;
  };
};

export function useGetUserAllowedChangePassword() {
  return useQuery<Response>(
    "user-allowed-change-password-key",
    async () => {
      const { data } = await API_AUTH_V2.get<Response>("/auth/password/change/allowed");
      return data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  );
}
