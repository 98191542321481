export const ENVS = {
  NYLAS_CLIENT_ID: process.env.REACT_APP_NYLAS_CLIENT_ID,
  APP_DOMAIN: process.env.REACT_APP_APP_DOMAIN,
  WEBSITE_DOMAIN: process.env.REACT_APP_WEBSITE_DOMAIN,

  // APIs
  BACK_END_API_URL: process.env.REACT_APP_BACK_END_API_URL,
  AUTH_API_URL: process.env.REACT_APP_AUTH_API_URL,
  NOTIFICATION_API_URL: process.env.REACT_APP_NOTIFICATION_API_URL,
  NOTIFICATION_SOCKET_URL: process.env.REACT_APP_NOTIFICATION_SOCKET_URL,
  CHALLENGES_API_URL: process.env.REACT_APP_CHALLENGES_API_URL,
  RATINGS_API_URL: process.env.REACT_APP_RATINGS_API_URL,
  MANAGER_NYLAS_API: process.env.REACT_APP_MANAGER_NYLAS_API,

  MANAGMENT_API_URL: process.env.REACT_APP_MANAGMENT_API_URL,
  INTELLIGENCE_API_URL: process.env.REACT_APP_INTELLIGENCE_API_URL,
  CRM_PIPE_API_URL: process.env.REACT_APP_CRM_PIPE_API_URL,
};

const NYLAS_SCOPES_MICROSOFT = [
  "email",
  "calendar",
  "calendar.read_only",
  "contacts",
  "contacts.read_only",
  "room_resources.read_only",
];
const NYLAS_SCOPES_GOOGLE = ["calendar"];

export const GLOBAL_CONSTANTS = {
  NYLAS_AUTHORIZE_URL_MICROSOFT: `https://api.nylas.com/oauth/authorize?client_id=${
    ENVS.NYLAS_CLIENT_ID
  }&redirect_uri=${
    ENVS.APP_DOMAIN
  }/enrollments&response_type=token&scopes=${NYLAS_SCOPES_MICROSOFT.join(",")}`,
  NYLAS_AUTHORIZE_URL_GOOGLE: `https://api.nylas.com/oauth/authorize?client_id=${
    ENVS.NYLAS_CLIENT_ID
  }&redirect_uri=${
    ENVS.APP_DOMAIN
  }/enrollments&response_type=token&scopes=${NYLAS_SCOPES_GOOGLE.join(",")}&provider=gmail`,

  AWS_STARTUPS_DECKS: "https://storage.googleapis.com/nodeuploads2021/challenges/decks",
  AWS_CHALLENGES_LOGOS: "https://storage.googleapis.com/nodeuploads2021/challenges/images",
  AWS_CRM_BUCKET_IMAGES: "https://storage.googleapis.com/nodeuploads2021/inn-crm-nc/images",
  AWS_IMAGES_BUCKET: "https://storage.googleapis.com/nodeuploads2021",

  AWS_GENERIC_ASSETS_BUCKET: "https://storage.googleapis.com/inn-generic-assets",
  AWS_FLAGS_BUCKET: "https://storage.googleapis.com/inn-generic-assets/flags",

  GA_MEASUREMENT_ID: "G-RPEMQB5MK2",
  FAKE_IMG_URL: "https://bulma.io/images/placeholders/128x128.png",
  FAKE_USER_IMG_URL:
    "https://storage.googleapis.com/innovation-website-files/user-avatar.webp",

  USER_ROW_INITIAL_VALUES: {
    username: "",
    email: "",
    id_group: "",
    company: "",
    id: null,
    status: 1,
    password: "",
  },
  FILTER_STORE_VALUES_KEYS: {
    coutry: "inn_country_filter_key",
    series: "inn_series_filter_key",
    industry: "inn_industry_filter_key",
    technology: "inn_technology_filter_key",
    isSavedFilter: "inn_is_saved_filter_key",
    savedFilterInfos: "inn_saved_filter_infos_key",
    saveFilterTotalResults: "inn_save_filter_total_results_key",
    isChangedFilter: "inn_is_changed_filter_key",
    oldIsSavedFilerState: "old_filter_state",
    originalFilterValue: "original_filter_values",
    loadedSearchValues: "loaded_search_values",
    storeSearchValues: "store_search_values",
    storeEmployeesMinValue: "store_employees_min_value",
    storeEmployeesMaxValue: "store_employees_max_value",
  },
  FILTER_FIELDS_STATES_STORE_KEY: "filterFieldsStates",
  NOTES_TYPES: ["Other", "Backlog", "In progress", "Concluded", "Abandoned"],
  NOTES_TYPES_COLORS: ["#444", "#ffa000", "#4169E1", "#43a047", "#d32f2f"],

  LANGUAGE_STORAGE_KEY: "language",
  USER_ID_STORAGE_KEY: "id",

  INDUSTRIES: [
    "Agrotech",
    "Beauty & Fashion Tech",
    "Construtech",
    "Edtech",
    "Energytech",
    "Entertainment",
    "Fintech",
    "Govtech",
    "Hard Science Tech",
    "Hardware Tech",
    "Human Resources Tech",
    "Industry X.0",
    "Lawtech",
    "Martech",
    "Mobility",
    "Non-Profit",
    "Pharma & Biotech",
    "Real Estate",
    "Retail Tech",
    "Software",
    "Sports Tech",
    "Supply Chain & Logistics",
    "Sustainability",
    "Travel Tech",
    "Foodtech",
    "Security",
  ],

  SERIES: [
    "Angel",
    "Grant",
    "Accelerator & Incubator",
    "Pre-Seed",
    "Seed",
    "Series A",
    "Series B",
    "Series C",
    "Series D",
    "Series E",
    "Series F",
    "Series G",
    "Series H",
    "Series I",
    "Series J",
    "Series K",
    "Private Equity",
    "Later Stage VC",
    "IPO",
    "Post IPO",
    "Spin-Of",
    "Bankruptcy",
    "Out of Business",
    "Merge & Acquisition",
    "Convertible Note",
    "Equity Crowdfunding",
    "Initial Coin Offering",
    "Series Unknown",
    "Debt Financing",
    "Secondary Market",
    "Corporate Round",
    "Partnerships",
    "Undisclosed",
  ],

  TECHNOLOGIES: [
    "Artificial Intelligence & Big Data",
    "AR & VR",
    "Blockchain",
    "Cleantech",
    "IoT",
    "Mobile",
    " Robotics & Drones",
    "SaaS",
  ],

  MONTHS_INDEXES: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

  CURRENCIES: [
    "AED",
    "ARS",
    "AUD",
    "BGN",
    "BHD",
    "BND",
    "BRL",
    "BWP",
    "CAD",
    "CHF",
    "CLP",
    "CNY",
    "COP",
    "CZK",
    "DKK",
    "EUR",
    "GBP",
    "HKD",
    "HRK",
    "HUF",
    "IDR",
    "ILS",
    "INR",
    "IRR",
    "ISK",
    "JPY",
    "KRW",
    "KWD",
    "KZT",
    "LKR",
    "LYD",
    "MUR",
    "MXN",
    "MYR",
    "NOK",
    "NPR",
    "NZD",
    "OMR",
    "PHP",
    "PKR",
    "PLN",
    "QAR",
    "RON",
    "RUB",
    "SAR",
    "SEK",
    "SGD",
    "THB",
    "TRY",
    "TTD",
    "TWD",
    "USD",
    "VEF",
    "ZAR",
  ],

  FILES_TYPES: {
    VIDEOS: [
      "WEBM",
      "MPG",
      "MP2",
      "MPEG",
      "MPE",
      "MPV",
      "OGG",
      "MP4",
      "M4P",
      "M4V",
      "AVI",
      "WMV",
      "MOV",
      "QT",
      "FLV",
      "SWF",
    ],
    IMAGES: ["JPEG", "JPG", "PNG", "GIF", "TIFF", "WEBP", "SVG"],
  },
};
