import React, { createContext, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import API from "src/services/api";

export const GoalsAndProjectsContext = createContext();

export function GoalsAndProjectsProvider({ children }) {
  const { t } = useTranslation();
  const [goalsCnWithStartup, setGoalsCnWithStartup] = useState([]);
  const [goalsCnWithStartupTotal, setGoalsCnWithStartupTotal] = useState(0);
  const [goalProjectsData, setGoalProjectsData] = useState([]);
  const [goalProjectsDataTotal, setGoalProjectsDataTotal] = useState(0);
  const [projectsCnWithStartupBasicData, setProjectsCnWithStartupBasicData] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  // const [projectsAndGoalsStatus, setProjectsAndGoalsStatus] = useState([]);

  // useQuery(
  //   "projects_and_goals_status",
  //   async () => {
  //     const { data } = await API.get("/gp/status");
  //     return data;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       setProjectsAndGoalsStatus(data.status);
  //     },
  //     retry: 2,
  //     refetchOnWindowFocus: false,
  //   },
  // );

  const getGoalsCnWithStartupMutation = useMutation(
    ({ startupId, paginate = false, page = 1, limit = 10 }) => {
      return API.get(`/gp/connections/goals_startup/${startupId}`, {
        params: {
          paginate,
          page,
          limit,
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        setGoalsCnWithStartup(data.goals);
        setGoalsCnWithStartupTotal(data.total);
      },
      retry: 2,
    },
  );

  const disconnectGoalCnWithStartupMutation = useMutation(
    ({ goalId, startupId }) => {
      return API.delete("/gp/connections/remove_startup_goal/", {
        data: {
          goalId,
          startupId,
        },
      });
    },
    {
      onSuccess: () => {
        toast.success(t("connection_with_goal_removed"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
      retry: 2,
    },
  );

  const connectGoalWithStartupMutation = useMutation(
    ({ goalId, startupId }) => {
      return API.post("/gp/connections/startup_goal", {
        goalId,
        startupId,
      });
    },
    {
      onSuccess: () => {
        toast.success(t("connection_with_goal_added"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
      retry: 2,
    },
  );

  const getGoalProjectsDataMutation = useMutation(
    ({ goalId }) => {
      return API.get(`/gp/goal/projects/${goalId}?type=2`);
    },
    {
      onSuccess: ({ data }) => {
        setGoalProjectsData(data.projects);
        setGoalProjectsDataTotal(data.total);
      },
      retry: 2,
    },
  );

  const getProjectsCnWithStartupBasicDataMutation = useMutation(
    ({ startupId, paginate = false, page = 1, limit = 10 }) => {
      return API.get(`/gp/connections/projects_startup/${startupId}`, {
        params: {
          paginate,
          page,
          limit,
        },
      });
    },
    {
      onSuccess: ({ data }) => {
        setProjectsCnWithStartupBasicData(data.projects);
      },
      retry: 2,
    },
  );

  const getProjectTypesMutation = useMutation(
    () => {
      return API.get("gp/project/types/list");
    },
    {
      onSuccess: ({ data }) => {
        setProjectTypes(data.types);
      },
    },
  );

  const connectProjectWithStartupMutation = useMutation(
    ({ projectId, startupId, goalId }) => {
      return API.post("/gp/connections/startup_project", {
        projectId,
        startupId,
        goalId,
      });
    },
    {
      onSuccess: () => {
        toast.success(t("project_connected_with_startup_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  const disconnectProjectFromStartupMutation = useMutation(
    ({ projectId, startupId }) => {
      return API.delete("/gp/connections/remove_startup_project", {
        data: {
          projectId,
          startupId,
        },
      });
    },
    {
      onSuccess: () => {
        toast.success(t("project_disconnected_this_startup_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  const createProjectMutation = useMutation(
    (data) => {
      return API.post("/gp/project/create", data);
    },
    {
      onSuccess: () => {
        toast.success(t("created_project_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  const updateProjectMutation = useMutation(
    (data) => {
      return API.put(`/gp/project/update/${data.id}`, data);
    },
    {
      onSuccess: () => {
        toast.success(t("updated_project_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  const addProjectTypesMutation = useMutation((data) => {
    return API.post(`/gp/project/types/add/${data.projectId}`, { types: data.types });
  });

  const removeProjectTypeMutation = useMutation(({ projectId, typeId }) => {
    return API.delete("/gp/project/types/remove", {
      data: {
        typeId,
        projectId,
      },
    });
  });

  const createGoalMutation = useMutation(
    (data) => {
      return API.post("/gp/goal/create", data);
    },
    {
      onSuccess: ({ data }) => {
        toast.success(t("created_goal_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  const updateGoalMutation = useMutation(
    (data) => {
      return API.put(`/gp/goal/update/${data.id}`, data);
    },
    {
      onSuccess: ({ data }) => {
        toast.success(t("updated_goal_success"));
      },
      onError: () => {
        toast.error(t("unexpected_error"));
      },
    },
  );

  return (
    <GoalsAndProjectsContext.Provider
      value={{
        goalsCnWithStartup,
        goalsCnWithStartupTotal,
        getGoalsCnWithStartupMutation,
        disconnectGoalCnWithStartupMutation,
        getGoalProjectsDataMutation,
        goalProjectsData,
        goalProjectsDataTotal,
        projectsCnWithStartupBasicData,
        getProjectsCnWithStartupBasicDataMutation,
        getProjectTypesMutation,
        projectTypes,
        connectProjectWithStartupMutation,
        disconnectProjectFromStartupMutation,
        // projectsAndGoalsStatus,
        createProjectMutation,
        updateProjectMutation,
        addProjectTypesMutation,
        removeProjectTypeMutation,
        updateGoalMutation,
        createGoalMutation,
        connectGoalWithStartupMutation,
      }}
    >
      {children}
    </GoalsAndProjectsContext.Provider>
  );
}

export function useGoalsAndProjects() {
  const context = React.useContext(GoalsAndProjectsContext);
  return context;
}
