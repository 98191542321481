import { ReactNode } from "react";
import Button from "@mui/material/Button";

import {
  AuthLayoutWrapper,
  AuthLayoutContainer,
  AuthLayoutHeader,
  AuthLayoutHeaderLogo,
  AuthLayoutHeaderNav,
  AuthLayoutChildren,
} from "./styles";
import { LanguageSelector } from "src/components/language-selector";

type Props = {
  children: ReactNode;
  headerButtonAction: () => void;
  headerButtonText: string;
};

export function AuthLayout({ children, headerButtonAction, headerButtonText }: Props) {
  return (
    <AuthLayoutWrapper>
      <AuthLayoutContainer>
        <AuthLayoutHeader>
          <AuthLayoutHeaderLogo>
            <img
              src='https://storage.googleapis.com/inn-generic-assets/images/logos/new-logo.png'
              alt='Innovation Intelligence'
            />
          </AuthLayoutHeaderLogo>

          <AuthLayoutHeaderNav>
            <LanguageSelector />
            <Button
              variant='outlined'
              color='primary'
              size='large'
              onClick={headerButtonAction}
            >
              {headerButtonText}
            </Button>
          </AuthLayoutHeaderNav>
        </AuthLayoutHeader>
      </AuthLayoutContainer>

      <AuthLayoutChildren>{children}</AuthLayoutChildren>

      {/* <div id='auth-layout-bg-left' className='auth-layout-bg'>
        <img
          src='https://storage.googleapis.com/inn-generic-assets/images/platform/auth-bg-left.svg'
          alt='Innovation Intelligence'
        />
      </div>

      <div id='auth-layout-bg-right' className='auth-layout-bg'>
        <img
          src='https://storage.googleapis.com/inn-generic-assets/images/platform/auth-bg-right.svg'
          alt='Innovation Intelligence'
        />
      </div> */}
    </AuthLayoutWrapper>
  );
}
