import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { InputField, PasswordInputField } from "src/modules/auth/components/form-fields/input";
import { emailPattern } from "src/shared/validators";
import { FieldErrorMessage } from "src/modules/auth/components/form-fields/styled";
import { AuthLoginPagePasswordForgot } from "./styles";
import { AuthFormBase, AuthFormBaseForm, AuthFormBaseFormGroup } from "../auth-form-base";
import { ILoginFormData } from "../../dtos";
import { ExtendedButton } from "src/components/v2/buttons";

interface IProps {
  handleSubmitForm: (data: ILoginFormData) => void;
  isLoading?: boolean;
}

export function AuthLoginForm({ handleSubmitForm, isLoading }: IProps) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginFormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<ILoginFormData> = (data) => {
    handleSubmitForm(data);
  };

  return (
    <AuthFormBase title={t("sign_in")}>
      <AuthFormBaseForm onSubmit={handleSubmit(onSubmit)}>
        <AuthFormBaseFormGroup>
          <Controller
            name='email'
            control={control}
            rules={{ required: true, pattern: emailPattern }}
            render={({ field }) => (
              <InputField
                hookFormProps={field}
                label={t("type_your_email")}
                isError={!!errors.email}
              />
            )}
          />

          {errors.email && errors.email.type === "required" && (
            <FieldErrorMessage>{t("email_required")}</FieldErrorMessage>
          )}

          {errors.email && errors.email.type === "pattern" && (
            <FieldErrorMessage>{t("invalid_email")}</FieldErrorMessage>
          )}
        </AuthFormBaseFormGroup>

        <AuthFormBaseFormGroup>
          <Controller
            name='password'
            control={control}
            rules={{ required: true, minLength: 8 }}
            render={({ field }) => (
              <PasswordInputField
                hookFormProps={field}
                label={t("type_your_password")}
                isError={!!errors.password}
              />
            )}
          />

          {errors.password && errors.password.type === "required" && (
            <FieldErrorMessage>{t("password_required")}</FieldErrorMessage>
          )}

          {errors.password && errors.password.type === "minLength" && (
            <FieldErrorMessage>{t("invalid_password")}</FieldErrorMessage>
          )}
        </AuthFormBaseFormGroup>

        <Link to='/password-recovery' style={{ width: "fit-content" }}>
          <AuthLoginPagePasswordForgot>{t("forgot_password")}</AuthLoginPagePasswordForgot>
        </Link>

        <ExtendedButton
          text={t("sign_in")}
          variant='contained'
          color='primary'
          size='large'
          type='submit'
          isLoading={isLoading}
        />
      </AuthFormBaseForm>
    </AuthFormBase>
  );
}
