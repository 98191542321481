import CircularProgress from "@mui/material/CircularProgress";
import { Stitches } from "src/styles/stitches";

const Loading = ({
  text = "",
  isShow = false,
  color = "primary",
  size = 26,
  padding = 10,
}) => {
  return isShow ? (
    <div style={{ width: "100%", textAlign: "center", padding }}>
      <CircularProgress size={size} color={color} /> <br />
      <span
        style={{
          color: String(Stitches.theme.colors["gray800"]),
          marginTop: 30,
        }}
      >
        {text}
      </span>
    </div>
  ) : null;
};

export default Loading;
