import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiGlobe } from "react-icons/fi";

import { useMultiLanguageStore } from "src/contexts/multi-language-context";
import { languages, storeLanguage, getStoredLanguage } from "./utils";

import { LanguageSelectorButton, LanguageSelectorWrapper } from "./styles";

type Props = {
  theme?: "light" | "gray";
};

export const LanguageSelector = ({ theme }: Props) => {
  const { i18n } = useTranslation();
  const { setLanguage, language } = useMultiLanguageStore();
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null);

  const handleSelectLanguage = (code: string) => {
    i18n.changeLanguage(code);
    setLanguage(code);
    storeLanguage(code);
    setAnchorEl(null);
  };

  useEffect(() => {
    const storedLanguage = getStoredLanguage();

    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
      storeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [i18n, setLanguage]);

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <LanguageSelectorWrapper>
        <LanguageSelectorButton onClick={(e) => handleClick(e)} theme={theme}>
          <FiGlobe /> {language.toUpperCase()}
        </LanguageSelectorButton>

        <Menu
          id='language-selector-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ zIndex: 999999 }}
        >
          {languages.map((language) => (
            <MenuItem key={language.code} onClick={() => handleSelectLanguage(language.code)}>
              {language.name}
            </MenuItem>
          ))}
        </Menu>
      </LanguageSelectorWrapper>
    </ClickAwayListener>
  );
};
