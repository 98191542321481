import { useQuery } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";

type Response = {
  data: {
    authId: string;
    qrCode: string;
  };
};

export function useSetupMfa(authId: string) {
  return useQuery<Response>(
    "mfa-setup-key",
    async () => {
      const { data } = await API_AUTH_V2.post<Response>("/auth/mfa/setup", { authId });
      return data;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}
