import React, { useState } from "react";

export const AuthContext = React.createContext({});

const AuthProvider = (props) => {
  const [user, setUser] = useState({ username: "", email: "" });

  return (
    <AuthContext.Provider value={{ user, setUser }}>{props.children}</AuthContext.Provider>
  );
};

export default AuthProvider;
