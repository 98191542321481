import { useHistory } from "react-router-dom";

export function useLogoutUser() {
  const history = useHistory();

  const logoutApplication = () => {
    localStorage.clear();

    if (history) {
      history.replace("/login");
      window.location.reload();
      return;
    }

    window.location.href = "/login";
    window.location.reload();
  };

  return {
    logoutApplication,
  };
}
