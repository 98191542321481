import toast, { ToastPosition } from "react-hot-toast";
import ReactHtmlParser from "react-html-parser";
import { FiAlertCircle, FiAlertTriangle, FiCheckCircle } from "react-icons/fi";

import { CustomToastWrapper, CustomToastContent, CustomToastText } from "./styles";

type Props = {
  message: string;
  type: "success" | "error" | "warning" | "info";
  onClose?: Function;
  timeout?: number;
  position?: ToastPosition;
};

const iconsType = {
  success: <FiCheckCircle />,
  error: <FiAlertTriangle />,
  warning: <FiAlertCircle />,
  info: <FiAlertCircle />,
};

export function customToast({
  message,
  onClose = () => {},
  type,
  timeout = 3000,
  position = "bottom-center",
}: Props) {
  setTimeout(async () => {
    await onClose();
  }, timeout);

  return toast.custom(
    <CustomToastWrapper type={type}>
      <CustomToastContent type={type}>
        {iconsType[type]}

        <CustomToastText>{ReactHtmlParser(message)}</CustomToastText>
      </CustomToastContent>
    </CustomToastWrapper>,
    {
      duration: timeout,
      position,
    },
  );
}
