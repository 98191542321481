import { Stitches } from "src/styles/stitches";

export const AuthFormBaseWrapper = Stitches.styled("section", {
  width: "100%",
  maxWidth: "380px !important",
  boxShadow: "$newShadow",
  backgroundColor: "$whitePure",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "$4",
  padding: "$3",
});

export const AuthFormBaseHeader = Stitches.styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "$2",
});

export const AuthFormBaseSocialProviders = Stitches.styled("div", {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "2fr auto",
  gridGap: "$4",

  "@media screen and (max-width: 420px)": {
    gridTemplateColumns: "1fr",
    gridGap: "$2",
  },
});

export const AuthFormBaseMainProvider = Stitches.styled("button", {
  width: "100%",
  height: "$2",
  backgroundColor: "$whitePure",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$1",
  fontSize: "$1",
  color: "$gray800",
  fontWeight: "$semiBold",
  border: "1px solid $gray600",
  borderRadius: "$default",
  padding: "0 $1",

  "& > img": {
    width: "$iconMedium",
    height: "$iconMedium",
    objectFit: "contain",
  },
});

export const AuthFormBaseOthersProviders = Stitches.styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "$1",

  "& > button": {
    width: "$1",
    height: "$1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "$whitePure",

    "& > img": {
      width: "80%",
      height: "80%",
    },
  },
});

export const AuthFormBaseChildren = Stitches.styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
});

export const AuthFormBaseForm = Stitches.styled("form", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "$2",

  "& > *": {
    width: "100%",
  },
});

export const AuthFormBaseFormGroup = Stitches.styled("div", {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "$1",

  "& > *": {
    width: "100%",
  },
});
