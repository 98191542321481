import { Stitches } from "src/styles/stitches";

export const CustomButtonWrapper = Stitches.styled("button", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$3",

  "& > svg": {
    color: "CurrentColor",
  },

  "&:disabled": {
    color: "rgba(0, 0, 0, 0.26) !important",
    boxShadow: "none !important",
    backgroundColor: "rgba(0, 0, 0, 0.12) !important",
  },

  variants: {
    size: {
      small: {
        height: 35,

        "& > svg": {
          width: 24,
          height: 24,
        },
      },
      medium: {
        height: 40,

        "& > svg": {
          width: 28,
          height: 28,
        },
      },
      large: {
        height: 50,

        "& > svg": {
          width: "$iconLarge",
          height: "$iconLarge",
        },
      },
    },
    color: {
      primary: {
        backgroundColor: "$primary !important",
        color: "$whitePure !important",
      },

      blue: {
        backgroundColor: "$blue !important",
        color: "$whitePure !important",

        "&:hover:not(:disabled)": {
          backgroundColor: "$blue600 !important",
        },
      },
    },
  },

  defaultVariants: {
    size: "medium",
    color: "blue",
  },
});
