import { Stitches } from "src/styles/stitches";

export const AuthLayoutWrapper = Stitches.styled("div", {
  width: "100%",
  maxWidth: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  position: "relative",
  backgroundColor: "$newGray400",

  ".auth-layout-bg": {
    position: "absolute",
    bottom: 0,
    pointerEvents: "none",
  },

  "#auth-layout-bg-left": {
    left: 0,
  },

  "#auth-layout-bg-right": {
    right: 0,
  },

  "@media screen and (max-width: 1080px)": {
    ".auth-layout-bg": {
      display: "none",
    },
  },
});

export const AuthLayoutContainer = Stitches.styled("div", {
  width: "100%",
  padding: "0 $6",

  margin: "0 auto",
  maxWidth: "100%",

  "@media screen and (min-width: 1721px)": {
    maxWidth: 1600,
  },

  "@media screen and (max-width: 1720px)": {
    maxWidth: 1300,
  },

  "@media screen and (max-width: 1410px)": {
    maxWidth: 1080,
  },

  "@media screen and (max-width: 1200px)": {
    maxWidth: 980,
  },

  "@media screen and (max-width: 1080px)": {
    maxWidth: 720,
  },

  "@media screen and (max-width: 830px)": {
    maxWidth: 560,
  },

  "@media screen and (max-width: 670px)": {
    maxWidth: 420,
  },
});

export const AuthLayoutHeader = Stitches.styled("header", {
  width: "100%",
  maxWidth: "100%",
  padding: "$4 0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "$4",

  "@media screen and (max-width: 670px)": {
    flexDirection: "column",
    gap: "$2",
  },
});

export const AuthLayoutHeaderLogo = Stitches.styled("div", {
  width: "100%",
  maxWidth: 140,
  height: "auto",

  "& > img": {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
});

export const AuthLayoutHeaderNav = Stitches.styled("nav", {
  width: "fit-content",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "$3",
});

export const AuthLayoutChildren = Stitches.styled("main", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 $3 $11 $3",
});
