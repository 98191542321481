import { useQuery } from "react-query";
import { API_AUTH_V2 } from "src/services/apis/auth";

type Response = {
  data: {
    authId: string;
    enabled: boolean;
    verified: boolean;
  };
};

export function useGetMfaState() {
  return useQuery<Response>(
    "mfa-state-key",
    async () => {
      const { data } = await API_AUTH_V2.get<Response>("/auth/mfa/state");
      return data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
    },
  );
}

export function useGetMfaStateByAuthId(authId: string) {
  return useQuery<Response>(
    "mfa-state-by-auth-id-key",
    async () => {
      const { data } = await API_AUTH_V2.get<Response>(`/auth/login/mfa/state/${authId}`);
      return data;
    },
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      staleTime: 0,
      enabled: !!authId,
    },
  );
}
