import { Stitches } from "src/styles/stitches";

export const CustomToastWrapper = Stitches.styled("div", {
  width: "fit-content",
  minWidth: 600,
  padding: "$3 $2",
  borderRadius: "$default",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$2",
  zIndex: 999999999999,

  variants: {
    type: {
      success: {
        backgroundColor: "$greenLight",
      },
      error: {
        backgroundColor: "$redLight",
      },
      warning: {
        backgroundColor: "$yellowLight",
      },
      info: {
        backgroundColor: "$blueLight",
      },
    },
  },

  defaultVariants: {
    type: "success",
  },
});

export const CustomToastContent = Stitches.styled("div", {
  width: "100%",
  maxWidth: 600,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "$2",

  "& > svg": {
    width: "$iconLarge",
    height: "$iconLarge",
  },

  variants: {
    type: {
      success: {
        "& > svg": {
          color: "$green",
        },
      },
      error: {
        "& > svg": {
          color: "$red",
        },
      },
      warning: {
        "& > svg": {
          color: "$newGray500",
        },
      },
      info: {
        "& > svg": {
          color: "$blue",
        },
      },
    },
  },

  defaultVariants: {
    type: "success",
  },
});

export const CustomToastText = Stitches.styled("p", {
  fontSize: "$1",
  fontWeight: "$medium",
  textAlign: "center",
  color: "$newGray500",
});
