import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { THEME_TOKENS } from "./theme-tokens";

const theme = createTheme({
  palette: {
    primary: {
      main: THEME_TOKENS.colors.primary,
    },
    secondary: {
      main: THEME_TOKENS.colors.red,
    },
    success: {
      main: "#129d5a",
      100: "#a9ebcb",
    },
  },
});

function CustomThemeProvider({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default CustomThemeProvider;
