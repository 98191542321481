import { Stitches } from "src/styles/stitches";

export const ModalDefaultTitleWrapper = Stitches.styled("div", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "$1",

  "& > svg": {
    width: "$iconMedium",
    height: "$iconMedium",
    color: "$primary",
  },

  "& > strong": {
    fontSize: "$2 !important",
    fontWeight: "$semiBold",
    textAlign: "left",
    color: "$gray800",
  },
});
