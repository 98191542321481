import { Stitches } from "src/styles/stitches";

export const SetupMfaAuthBaseFormQrCode = Stitches.styled("div", {
  width: "100%",
  maxWidth: 240,
  height: 200,

  borderRadius: "$default",

  "& > img": {
    width: "100%",
    height: "100%",
    maxHeight: 240,
    minHeight: 200,
    objectFit: "contain",
  },
});
