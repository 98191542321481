import { styled } from "@mui/material/styles";
import Button, { ButtonProps, buttonClasses } from "@mui/material/Button";
import { ReactNode } from "react";
import Loading from "src/components/loading";
import { CustomButtonWrapper } from "./styles";
import { THEME_TOKENS } from "src/styles/theme-tokens";

type Props = ButtonProps & {
  isLoading?: boolean;
  text?: string;
  children?: ReactNode;
};

export function ExtendedButton({ isLoading, text, ...props }: Props) {
  return (
    <>
      {!isLoading && <Button {...props}>{text}</Button>}
      {isLoading && <Loading isShow />}
    </>
  );
}

const BlueButtonContainedStyles = styled(Button)<ButtonProps>((theme) => ({
  [`&.${buttonClasses.root}`]: {
    whiteSpace: "nowrap",
    textTransform: "none",
    fontWeight: 600,
    backgroundColor: THEME_TOKENS.colors.blue,
    color: THEME_TOKENS.colors.whitePure,
    boxShadow: THEME_TOKENS.shadows.newShadow,
    paddingLeft: THEME_TOKENS.space[2],
    paddingRight: THEME_TOKENS.space[2],

    "&:hover": {
      backgroundColor: THEME_TOKENS.colors.blue600,
    },

    "&:disabled": {
      backgroundColor: THEME_TOKENS.colors.gray100,
      color: THEME_TOKENS.colors.neutral20,
    },
  },
}));

export function BlueButtonContained({ isLoading, text, children, ...props }: Props) {
  return (
    <>
      {!isLoading && (
        <BlueButtonContainedStyles {...props}>{text || children}</BlueButtonContainedStyles>
      )}
      {isLoading && (
        <div style={{ width: "fit-content" }}>
          <Loading
            isShow
            size={props.size === "medium" ? 20 : props.size === "small" ? 15 : undefined}
          />
        </div>
      )}
    </>
  );
}

const BlueButtonOutlinedStyles = styled(Button)<ButtonProps>((theme) => ({
  [`&.${buttonClasses.root}`]: {
    textTransform: "none !important",
    whiteSpace: "nowrap",
    fontWeight: 600,
    border: `1px solid ${THEME_TOKENS.colors.blue}`,
    color: THEME_TOKENS.colors.blue,
    paddingLeft: 10,
    paddingRight: 10,

    "&:hover": {
      border: `1px solid ${THEME_TOKENS.colors.blue}`,
    },

    "&:disabled": {
      borderColor: "#E0E0E0",
      color: "#E0E0E0",
    },
  },
}));

export function BlueButtonOutlined({ isLoading, text, children, ...props }: Props) {
  return (
    <>
      {!isLoading && (
        <BlueButtonOutlinedStyles {...props}>{text || children}</BlueButtonOutlinedStyles>
      )}
      {isLoading && (
        <Loading
          isShow
          size={props.size === "medium" ? 20 : props.size === "small" ? 15 : undefined}
        />
      )}
    </>
  );
}

type CustomButtonProps = typeof CustomButtonWrapper.defaultProps & {
  children: ReactNode;
};

export function CustomButton({ children, color, size, ...rest }: CustomButtonProps) {
  return (
    <CustomButtonWrapper color={color} size={size} {...rest}>
      {children}
    </CustomButtonWrapper>
  );
}
